
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCiwRZVpBuImki85Mp1I4pkJnL4vt4Cfwo",
  authDomain: "shopping-bazaar-dd43e.firebaseapp.com",
  projectId: "shopping-bazaar-dd43e",
  storageBucket: "shopping-bazaar-dd43e.appspot.com",
  messagingSenderId: "886663885692",
  appId: "1:886663885692:web:02095a0bca8a803481aaf9",
  measurementId: "G-YXMJQ8X6P2"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;