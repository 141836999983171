
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contact from "./components/pages/Contact";

import Kid from "./components/pages/Kid";
import Men from "./components/pages/Men";
import Women from "./components/pages/Women";

import Womenpage from "./components/pages/Womenpage";
import Menpage from "./components/pages/Menpage";
import Tshirt from "./components/pages/Tshirt";
import Jeans from "./components/pages/Jeans";
import Saree from "./components/pages/Saree";
import Western from "./components/pages/Western";
import Gowns from "./components/pages/Gowns";
import Blazer from "./components/pages/Blazer";
import Showroom from "./components/pages/Showroom";


function App() {
  return (
    
    <Router>
      
  
    
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/kid" element={<Kid/>}/>
          <Route axact path="/casual" element={<Men/>}/>
          <Route axact path="/churidar" element={<Women/>}/>
          <Route axact path="/women" element={<Womenpage/>}/>
          <Route axact path="/men" element={<Menpage/>}/>
          <Route axact path="/tshirt" element={<Tshirt/>}/>
          <Route axact path="/jeans" element={<Jeans/>}/>
          <Route axact path="/saree" element={<Saree/>}/>
          <Route axact path="/western" element={<Western/>}/>
          <Route axact path="/gowns" element={<Gowns/>}/>
          <Route axact path="/blazer" element={<Blazer/>}/>
          <Route axact path="/showroom" element={<Showroom/>}/>
          
    </Routes>
    <Footer/>
  
    </div>
   
    </Router>
  );
}

export default App;
