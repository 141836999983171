
import React from 'react'
import Navbar from './../inc/Navbar';
import SHAREE from "../images/SAREE.jpg";
import Churidar from "../images/churi.jpg";
import { Link } from 'react-router-dom';
import Groundwomewn from "../images/ground.jpg";
import Westenrdress from "../images/weastern.jpg";
import ScrollToTop from "react-scroll-to-top";

function Womenpage() {
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
      <br/>
      <br/>
      <ScrollToTop smooth top="100" color="Darkblue"/>
      <div className="album py-2">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border-White box shadow">
                          <Link to="/saree">
                        <img src={SHAREE} className="card-img-top"   alt="SHAREE" />
                        </Link>
                            <div className="card-body">
                                <center>
                                <h2><b style={{color:"Gray"}}> SAREE</b></h2>
                                </center>
                                <center>
                                <Link to="/saree">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/churidar">
                        <img src={Churidar} className="card-img-top"   alt="Churidar" />
                        </Link>
                            <div className="card-body">
                            <center>
                                <h2><b style={{color:"Gray"}}> CHURIDAR</b></h2>
                                </center>
                            <center>
                            <Link to="/churidar">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>


<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            
            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border-White box shadow">
                          <Link to="/gowns">
                        <img src={Groundwomewn} className="card-img-top"   alt="Ground" />
                        </Link>
                            <div className="card-body">
                            <center>
                                <h2><b style={{color:"Gray"}}> GOWNS</b></h2>
                                </center>
                                <center>
                                <Link to="/gowns">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            
            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border-White box shadow">
                          <Link to="/western">
                        <img src={Westenrdress} className="card-img-top"   alt="Westenrdress" />
                        </Link>
                            <div className="card-body">
                            <center>
                                <h2><b style={{color:"Gray"}}> WESTERN</b></h2>
                                </center>
                                <center>
                                <Link to="/western">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
    </div>
  )
}

export default Womenpage
