
import React from 'react'
import {Link} from "react-router-dom";

function Navbar() {
  return (
    <div>
      <div className='fixed-top'>
      <div className="p-2 mb-0 bg-secondary text-white">
        <center>
       
       <b>SHOPPING BAZAAR - FAMILY SHOP </b> <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div>

       </center>
        </div>

      <nav className="navbar navbar-expand-lg navbar-light bg-white shadow">
  <div className="container-fluid">
    <Link to="/" className="navbar-brand me-3" ><b style={{color:"red"}}>Shopping Bazaar</b></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" href="#"><b style={{color:"Darkblue"}}>Home</b></Link>
        </li>
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" href="#"><b style={{color:"Darkblue"}}>About Us </b></Link>
        </li>

        <li className="nav-item">
          <Link to="/showroom" className="nav-link active" aria-current="page" href="#"><b style={{color:"Darkblue"}}>Showroom</b></Link>
        </li>
       
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
         <b style={{color:"Darkblue"}}>  Product</b>
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/women" className="dropdown-item" href="#">Women</Link></li>
            <li><Link to="/men" className="dropdown-item" href="#">Men</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/kid" className="dropdown-item" href="#">Kids</Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link"  tabindex="-1" aria-disabled="true"><b>Contact</b></Link>
        </li>
      </ul>
      <form className="d-flex">
       <a className="btn btn-outline-success" href="tel: 9933798896"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg> +91 9735383901</a>
       
      </form>
    </div>
  </div>
</nav>
</div>
    </div>
  )
}

export default Navbar
