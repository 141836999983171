import React from "react";
import Navbar from '../inc/Navbar';
import Shopping from "../images/shoppingbazaar.jpg";
import Bazaar from "../images/busstand.jpg";
import ScrollToTop from "react-scroll-to-top";

function Showroom (){
    return(
        <>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <ScrollToTop smooth top="20" color="Darkblue" />
        <center>
            <h2 style={{color:"Gray"}}><b>SHOPPING BAZAAR SHOWROOM</b></h2>
        </center>
        <div className="album py-3">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-12" >
                         
            <div className="d-flex justify-content-between align-items-center">
                                <div className="card mb-4 me-3 border-white box shadow">
                           
                            <div class="card-body">
                          
<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
<img src={Shopping} className="card-img-top"   alt="Shopping" />
</button>


<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Shopping Bazaar | Karimpur, Natidanga More</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <img src={Shopping} className="card-img-top"   alt="Shopping" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div>
              
              <br/>
              <br/>
                              <center>
    <h5 style={{color: "Gray"}}className="card-title">Karimpur, Natidanga More</h5>
    </center>
                                            </div>
                         
                         
                            </div>
                            <div className="card mb-4 me-3 border-white box shadow">
                           
                            <div class="card-body">
                            
<button type="button" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
<img src={Bazaar} className="card-img-top"   alt="Shoppinbazaar" />
</button>


<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Shopping Bazaar | Karimpur New Bus Stand</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <img src={Bazaar} className="card-img-top"   alt="Shoppingbazaar" />
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div>
<br/>
<br/>

                            <center>
    <h5 style={{color: "Gray"}}className="card-title"> Karimpur, New Bus Stand</h5>
    </center>
                                            </div>
                         
                         
                            </div>
                            
                          
                            </div>

                </div>
                </div>
                
                </div>
                </div>
                <hr/>
          
        </>
    );
}

export default Showroom;