import React from 'react'
import Navbar from "../inc/Navbar";
import Slider from '../inc/Slider';

import Mens from "../images/mens12.jpg";
import Women from "../images/WOMEN.jpg";
import Kids from "../images/KIDS.jpg";
import {Link} from "react-router-dom";
import Slide4 from "../images/slide4.jpg";
import slide from "../images/slide3.jpg";
import slideShopping from "../images/Slide2.jpg";
import SlideShopping from "../images/SLIDE1.jpg";

import "./Home.css";
// import Mygif from "../images/eid-mubarak.gif";
import Latest from "../pages/Latest";
import ShoppingBazaarBuilding from "../images/sb.jpg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
function Home() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div>
      <Navbar/>
      <br/>
      <br/>
     
      <div className="album py-2">
        
        <div className="container">

            <div className="row">
              <h5>Shopping Bazaar</h5>
              </div>
              </div>
            </div>
      <Slider/>
     <br/>
      <div style={{color: "Gray", padding:"14px;"}} className="fixed-bottom "> <a href="tel: +91 9735383901" className="btn btn-primary" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-phone" viewBox="0 0 16 16">
  <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
  <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
</svg>  Call Us <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div> </a></div>
         

      <div className="album py-2">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                          <Link to="/men">
                        <img src={Mens} className="card-img-top"   alt="Men" />
                        </Link>
                            <div className="card-body">
                                <center>
                                <Link to="/men">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                    
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-4 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/women">
                        <img src={Women} className="card-img-top"   alt="Women" />
                        </Link>
                            <div className="card-body">
                            <center>
                            <Link to="/women">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>


<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            {/* style={{textAlign: "center"}} */}
                            <div className="col-md-4" >
                        <div className="card mb-4 border-white box shadow">
                        <Link to="/kid">
                        <img src={Kids} className="card-img-top"   alt="Kid" />
                        </Link>
                            <div className="card-body">
                            <center>
                            <Link to="/kid">
                               <button className="btn btn-dark">Click Now</button>
                               </Link>
                               </center>
                                
                                
                      
                                <div className="d-flex justify-content-between align-items-center">
                                   
                               
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                            <img src={ShoppingBazaarBuilding} className="img-fluid" alt="Shopping_Bazaar"/>
                            <div className="shoppingbazaar">
                              <br/>
                            <div className="album py-2">
        
        <div className="container">

            <div className="row">
              <br/>
              <h2> <b>SHOPPING BAZAAR</b> - Latest Churidar</h2>
              <br/>
              </div>
              </div>
            </div>
            <br/>
                            </div>
                      
                            <Latest/>
                            <div className="album py-2">
        
        <div className="container">

            <div className="row">
           
      <Carousel responsive={responsive}>
      <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={Slide4} alt="Shopping_Bazaar"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WOMEN COLLECTION </b></h6>
    <p className="card-text"><small>by Shopping Bazaar</small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={slide} alt="Shopping_Bazaar"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WOMEN COLLECTION </b></h6>
    <p className="card-text"><small>by Shopping Bazaar</small></p>
    </center>
  </div>
</div>
  <div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={slideShopping} alt="Shopping_Bazaar"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WOMEN COLLECTION </b></h6>
    <p className="card-text"><small>by Shopping Bazaar</small></p>
    </center>
  </div>
</div>



<div className="card border mb-3 me-3" >
  <img className="card-img-top"  src={SlideShopping} alt="SlideShopping"/>
  <div className="card-body text">
    <center>
    <h6 className="card-title mb-0"><b>WOMEN COLLECTION </b></h6>
    <p className="card-text"><small>by Shopping Bazaar</small></p>
    </center>
  </div>
</div>
</Carousel>
</div>
</div>

</div>
<br/>
    </div>
  )
}

export default Home
