// import React from 'react'
// import Shoppingbazaar from "../images/SHOPPINGB.jpg";
// import Womenpowers from "../images/womenpower.jpg";
// import MenShoppingbazaar from "../images/mend.jpg";
// import independenceday  from "../images/15th-slider.jpg";

// import { Link } from 'react-router-dom';

// function Slider() {
//   return (
//     <div>
//       <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
//   <div className="carousel-indicators">
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
//     <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

//   </div>
//   <div className="carousel-inner">
//   <div className="carousel-item active">
//       <Link to="/women">
//       <img src={independenceday} className="d-block w-100" alt="Shoppingbazaar"/>
//       </Link>
//     </div>
//     <div className="carousel-item ">
//       <Link to="/women">
//       <img src={Shoppingbazaar} className="d-block w-100" alt="Shoppingbazaar"/>
//       </Link>
//     </div>
//     <div className="carousel-item">
//       <img src={Womenpowers} className="d-block w-100" alt="Shoppingbazaar"/>
//     </div>
//     <div className="carousel-item">
//       <img src={MenShoppingbazaar} className="d-block w-100" alt="Shoppingbazaar"/>
//     </div>
//   </div>
//   <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
//     <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Previous</span>
//   </button>
//   <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
//     <span className="carousel-control-next-icon" aria-hidden="true"></span>
//     <span className="visually-hidden">Next</span>
//   </button>
// </div>
//     </div>
//   )
// }

// export default Slider




import React, { useState, useEffect }  from 'react';

import {Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'


function Slider() {
   const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [slider, setSlider] = useState([])
      const navigate = useNavigate()
  
      const sliderCollectionRef = collection(db, "slider");
      useEffect(() => {
  
          const getSlider = async () => {
              const data = await getDocs(sliderCollectionRef);
              setSlider(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getSlider()
      }, [])
  return (
    <div>

{slider.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } {
        return val;
      }
    }).slice(pagination.start, pagination.end).map((shoppingbazaar) => { return (
       
       
                       
                            <div className="card-body">
                                   
      <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
          
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>

        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
    
    
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src={shoppingbazaar.slider1} className="d-block w-100" alt="shoppingbazaarkarimpur"/>
        </div>
        <div className="carousel-item">
          <img src={shoppingbazaar.slider2} className="d-block w-100" alt="shoppingbazaar"/>
        </div>
       
        <div className="carousel-item">
          <img src={shoppingbazaar.slider3} className="d-block w-100" alt="shoppingbazaar"/>
        </div>
        <div className="carousel-item">
          <img src={shoppingbazaar.slider4} className="d-block w-100" alt="shoppingbazaar"/>
        </div>
   
      
       
      </div>
     
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  
                                
                               
                              
                                {/* <h5 style={{color: "Darkblue"}}className="card-text"> ₹{website.saleprice} </h5>
                                <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{website.delete}</del> </p> */}
                               

                               
                    
                              
                            </div>
                           
                                )})} 
                            
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={slider.length}
                /> */}
      
    </div>
  )
}

export default Slider

