import React, { useState, useEffect }  from 'react';
import {Link} from "react-router-dom";
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
import {db} from '../../firebase'

import Navbar from "../inc/Navbar";
import ScrollToTop from "react-scroll-to-top";

function Men() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(50);
    const [pagination, setPagination] =useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start,  end) =>{
        setPagination({start: start, end: end});
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [men, setMen] = useState([])
    const navigate = useNavigate()

    const menCollectionRef = collection(db, "men");
    useEffect(() => {

        const getMen = async () => {
            const data = await getDocs(menCollectionRef);
            setMen(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
        }

        getMen()
    }, [])
    return (
        
<>


<Navbar/>
<br/>
<br/>
<br/>
<br/>
<br/>

<ScrollToTop smooth top="100" color="Darkblue"/>


<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
    <li className="breadcrumb-item"><Link to="/women" >Women</Link></li>
    <li className="breadcrumb-item"><Link to="/saree" >Saree</Link></li>
    <li className="breadcrumb-item"><Link to="/churidar" >Churidar</Link></li>
    <li className="breadcrumb-item"><Link to="/gowns" >Gowns</Link></li>
    <li className="breadcrumb-item"><Link to="/western" >Western</Link></li>
    <li className="breadcrumb-item"><Link to="/tshirt" >T-Shirt</Link></li>
    <li className="breadcrumb-item"><Link to="/jeans" >Jeans</Link></li>
    <li className="breadcrumb-item"><Link to="/kid" >Kid</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Casual-Shirt</li>
  </ol>
</nav>
</div>
</div>
</div>

<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" class="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>

<div className="album py-0">
        
        <div className="container">

            <div className="row">
            {men.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
    

       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((shoppingbazaar) => { return (
       
            <div className="col-md-4 mt-4">
                        <div className="card mb-4 border- box ">
                          
                        <img  className="card-img-top" src={shoppingbazaar.img}   alt="Kid" />
                      
                            <div className="card-body">
                                <center>
                                <h5 style={{color:"Gray"}} className="card-text mb-2"> <b> { shoppingbazaar.title} </b></h5>
                                <p style={{color:"Gray"}} className="card-text mb-2"> <b> { shoppingbazaar.dis} </b></p>
                                </center>


                         
                              
                                <div className="d-flex justify-content-between align-items-center">
                              
                                        

                                       
                                    
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={men.length}
                /> */}
                            </div>
                            </div>
                            <br/>
                            
                           
</>

);
}

export default Men;